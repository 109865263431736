<template>
    <div class="team-invite-page">
        <div class="user-info-wrapper" v-if="show">
            <div class="title">个人信息</div>
            <div class="notice">{{orgName}}邀请你加入企业团队，请填写个人信息后提交申请</div>
            <el-form label-position="top" size="medium" style="width:292px; margin: 20px auto 30px auto;">
              <el-form-item label="姓名" required>
                <el-input v-model="form.name" placeholder="输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="手机号" required>
                <el-input v-model="form.phone" disabled placeholder="输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" required >
                <el-input v-model="form.email" placeholder="输入邮箱"></el-input>
              </el-form-item>
              <el-form-item class="text-center">
                <el-button round plain small @click="$router.replace('/')">取消</el-button>
                <el-button type="primary" round small @click="applyEvent">申请加入</el-button>
              </el-form-item>
            </el-form>
        </div>
        <el-dialog title="提示" :visible.sync="showDialog" destroy-on-close width="430px" top="20px">
            <div class="text-center" style="color: #00B670; font-size: 20px;"><i class="el-icon-success"></i></div>
            <div class="text-center fonts-15 margin-t-10">申请成功</div>
            <div class="text-center fonts-12 margin-t-10 line-height-1.5">您已申请加入{{orgName}}，<br>快去通知企业管理员审核吧！</div>
            <div class="text-center" slot="footer">
                <el-button type="primary" round size="small" @click="$router.replace('/')">好的</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { teamApi } from '@/utils/api';
import tools from '@/utils/tools';
export default {
    data () {
        return {
            show: false,
            orgName: '',
            form: {
                name: '',
                phone: '',
                email: '',
            },
            showDialog: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
        })
    },
    created() {
        
    },
    methods: {
        initPage() {
            let params = this.$route.params;
            console.log(params);
            let url = `${teamApi.queryPreJoinUserInfo}?inviteCode=${params.code}`;
            this.$ajax.get(url).then(res => {
                if(res.status == 200 && res.data) {
                    this.show = true;
                    this.orgName = res.data.orgName;
                    this.form = {
                        name: '',
                        phone: res.data.phone,
                        email: res.data.email,
                    }
                }
            })
            
        },
        applyEvent() {
            if(!this.form.name) {
                this.$message.error('请输入姓名');
                return;
            }
            /* if(!this.form.phone) {
                this.$message.error('请输入手机号');
                return;
            }else {
                if(!tools.checkPhone(this.form.phone)){
                    this.$message.error('手机号格式不合法');
                    return;
                }
            } */
            if(!this.form.email) {
                this.$message.error('请输入邮箱');
                return;
            }else {
                if(!tools.checkEmail(this.form.email)){
                    this.$message.error('邮箱格式不合法');
                    return;
                }
            }
            let url = teamApi.submitApplyJoin;
            let postData = {
                inviteCode: this.$route.params.code,
                name: this.form.name,
                email: this.form.email,
            };
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.showDialog = true;
                }
            })
            
        },
    },
    mounted() {
        this.initPage();
    }
}
</script>
<style lang="scss" scoped>
.team-invite-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .user-info-wrapper {
        width: 500px;
        min-height: 400px;
        background-color: #fff;
        border-radius: 10px;
        .title {
            padding: 15px 0;
            text-align: center;
            font-size: 14px;
            box-sizing: border-box;
            border-bottom: 1px solid #f2f2f2;
        }
        .notice {
            font-size: 12px;
            text-align: center;
            padding: 8px 0;
            color: #E98C02;
            background-color: #FDF5E6;
        }
    }
}
</style>
